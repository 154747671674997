import { post, get } from './common';

export const getDashboard = async () => {
  return get('home/dashboard', true);
};

export const getSearchCenter = async keyword => {
  return get('home/searchCenter?name=' + keyword, true);
};

export const editCenter = async idx => {
  return post('home/editCenter', { centerIdx: idx }, true);
};
