import { post, get } from './common';

export const loginApi = async (userId, password) => {
  return post(
    'user/login',
    {
      id: userId,
      password,
    },
    false
  );
};

export const register = async (userId, password, name, email) => {
  return post(
    'user/register',
    {
      userId,
      password,
      name,
      email,
    },
    true
  );
};

export const deleteUser = async () => {
  return post('user/delete', { userIdx }, true);
};

export const profile = async () => {
  return get('user/profile', true);
};
