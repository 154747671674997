import { createStore } from 'vuex';

export default createStore({
  state: {
    isUserLogin: true,
    userInfo: {
      userId: '',
      name: '',
      email: '',
      isSuper: false,
    },
    companyInfo: {
      name: '',
      address: '',
      phoneNumber: '',
      centerIdx: '',
      centerName: '',
    },
  },
  getters: {
    getUserInfo(state) {
      return state.userInfo;
    },
    getCompanyInfo(state) {
      return state.companyInfo;
    },
  },
  mutations: {
    userLogin(state, userInfo) {
      state.isUserLogin = true;
      state.userInfo.userId = userInfo.userId;
      state.userInfo.name = userInfo.name;
      state.userInfo.email = userInfo.email;
      state.userInfo.isSuper = userInfo.isSuper;
      state.companyInfo.name = userInfo.name;
      state.companyInfo.address = userInfo.address;
      state.companyInfo.phoneNumber = userInfo.phoneNumber;
      state.companyInfo.centerIdx = userInfo.centerIdx;
      state.companyInfo.centerName = userInfo.centerName;
    },
    userLogout(state) {
      state.isUserLogin = false;
      state.userInfo.userId = '';
      state.userInfo.name = '';
      state.userInfo.email = '';
      state.userInfo.isSuper = false;
      state.companyInfo.name = '';
      state.companyInfo.address = '';
      state.companyInfo.phoneNumber = '';
      state.companyInfo.centerIdx = '';
      state.companyInfo.centerName = '';
      localStorage.removeItem('ACCESS_TOKEN');
    },
  },
  actions: {},
  modules: {},
});
