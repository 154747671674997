<template>
  <div v-if="isMounted === true" class="home flex h-full w-full bg-hg-box002">
    <!-- SLIDE MENU start -->
    <div
      class="shrink-0 h-full w-[280px] hidden sm:flex bg-hgcare-primaryPressed flex-col pt-[30px] pb-[60px] text-hg-default2 text-h3-semibold"
    >
      <div class="flex flex-col px-[42px] pb-[33px]">
        <p class="pb-[6px] text-hg-default2 text-body2-medium">
          {{ companyInfo.name }}
        </p>
        <router-link to="/"
          ><img
            class="cursor-pointer"
            src="@/assets/logos/header.svg"
            alt="허그케어"
        /></router-link>
      </div>
      <router-link to="/">
        <div class="py-[20px] px-[42px] bg-hgcare-primary003 cursor-pointer">
          <div class="flex gap-[8px] items-center">
            <img src="@/assets/icons/home.svg" alt="홈" />
            <p>홈</p>
          </div>
        </div>
      </router-link>
      <router-link v-if="userInfo.isSuper === true" to="/center">
        <div class="py-[20px] px-[42px] hover:bg-hgcare-primary cursor-pointer">
          <div class="flex gap-[8px] items-center">
            <img src="@/assets/icons/center.svg" alt="관리자" />
            <p>관리자</p>
          </div>
        </div>
      </router-link>
      <div
        @click="logout()"
        class="py-[20px] px-[42px] mt-auto hover:bg-hgcare-primary cursor-pointer"
      >
        <div class="flex gap-[8px] items-center">
          <img src="@/assets/icons/logout.svg" alt="로그아웃" />
          <p>로그아웃</p>
        </div>
      </div>
    </div>
    <!-- SLIDE MENU end -->
    <div class="w-full h-full flex flex-col justify-between overflow-y-auto">
      <!-- Main Contents start -->
      <div
        class="w-full grid grid-cols-6 gap-[30px] pl-[40px] pr-[28px] py-[36px]"
      >
        <div
          class="bg-hg-box001 shadow-hg px-[22px] pt-[30px] pb-[40px] xl:col-span-4 col-span-6"
        >
          <div class="flex flex-col items-center">
            <p class="text-body1-semibold text-hgcare-primary002">
              오늘의 이용자수
            </p>
            <p class="mt-[10px] text-title-36">
              {{ summaryData.todayDate }}
            </p>
            <div class="flex xl:flex-row flex-col w-full gap-[30px] mt-[60px]">
              <div
                class="flex w-full flex-col items-center px-[40px] py-[16px] bg-hg-box004"
              >
                <div class="mb-[16px]">
                  <img src="@/assets/logos/logo_ssam_text.svg" alt="허그쌤" />
                </div>
                <div class="w-full flex gap-[20px] justify-between text-center">
                  <div class="flex flex-col items-center">
                    <p
                      class="text-title-52"
                      :class="[
                        summaryData.teacherData.totalCount === 0
                          ? 'text-hg-disabled'
                          : 'text-hg-primary',
                      ]"
                    >
                      {{ summaryData.teacherData.totalCount }}
                    </p>
                    <p class="text-body2-medium text-hg-info">전체</p>
                  </div>
                  <div class="flex flex-col items-center">
                    <p
                      class="text-title-52"
                      :class="[
                        summaryData.teacherData.videoCount === 0
                          ? 'text-hg-disabled'
                          : '',
                      ]"
                    >
                      {{ summaryData.teacherData.videoCount }}
                    </p>
                    <p class="text-body2-medium text-hg-info">화상상담</p>
                  </div>
                  <div class="flex flex-col items-center">
                    <p
                      class="text-title-52"
                      :class="[
                        summaryData.teacherData.chatCount === 0
                          ? 'text-hg-disabled'
                          : '',
                      ]"
                    >
                      {{ summaryData.teacherData.chatCount }}
                    </p>
                    <p class="text-body2-medium text-hg-info">허그봇</p>
                  </div>
                  <div class="flex flex-col items-center">
                    <p
                      class="text-title-52"
                      :class="[
                        summaryData.teacherData.testCount === 0
                          ? 'text-hg-disabled'
                          : '',
                      ]"
                    >
                      {{ summaryData.teacherData.testCount }}
                    </p>
                    <p class="text-body2-medium text-hg-info">자가검진</p>
                  </div>
                </div>
              </div>
              <div
                class="flex w-full flex-col items-center px-[40px] py-[16px] bg-hg-box004"
              >
                <div class="mb-[16px]">
                  <img
                    src="@/assets/logos/logo_friends_text.svg"
                    alt="허그프렌즈"
                  />
                </div>
                <div class="w-full flex gap-[20px] justify-between text-center">
                  <div class="flex flex-col items-center">
                    <p
                      class="text-title-52"
                      :class="[
                        summaryData.studentData.totalCount === 0
                          ? 'text-hg-disabled'
                          : 'text-hgfriends-primary',
                      ]"
                    >
                      {{ summaryData.studentData.totalCount }}
                    </p>
                    <p class="text-body2-medium text-hg-info">전체</p>
                  </div>
                  <div class="flex flex-col items-center">
                    <p
                      class="text-title-52"
                      :class="[
                        summaryData.studentData.videoCount === 0
                          ? 'text-hg-disabled'
                          : '',
                      ]"
                    >
                      {{ summaryData.studentData.videoCount }}
                    </p>
                    <p class="text-body2-medium text-hg-info">화상상담</p>
                  </div>
                  <div class="flex flex-col items-center">
                    <p
                      class="text-title-52"
                      :class="[
                        summaryData.studentData.chatCount === 0
                          ? 'text-hg-disabled'
                          : '',
                      ]"
                    >
                      {{ summaryData.studentData.chatCount }}
                    </p>
                    <p class="text-body2-medium text-hg-info">허그봇</p>
                  </div>
                  <div class="flex flex-col items-center">
                    <p
                      class="text-title-52"
                      :class="[
                        summaryData.studentData.testCount === 0
                          ? 'text-hg-disabled'
                          : '',
                      ]"
                    >
                      {{ summaryData.studentData.testCount }}
                    </p>
                    <p class="text-body2-medium text-hg-info">자가검진</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="bg-hg-box001 flex flex-col col-span-6 xl:col-span-2 shadow-hg px-[22px] pt-[30px] pb-[30px]"
        >
          <p class="text-h2-bold">담당 센터 설정</p>
          <div class="mt-[12px] relative">
            <img
              class="absolute z-40 right-[20px] top-[12px] cursor-pointer"
              src="@/assets/icons/search.svg"
              alt="검색"
            />
            <div
              v-if="isDropdownOpen === true"
              @click="isDropdownOpen = false"
              class="fixed w-screen h-screen top-0 left-0 z-10"
            ></div>
            <input
              type="text"
              @focus="isDropdownOpen = true"
              @input="getDropdownValues()"
              @keyup="dropdownKeyPress($event)"
              placeholder="센터를 검색하세요"
              v-model="dropdownSelected"
              class="py-[12px] pl-[20px] relative z-30 pr-[50px] rounded-[5px] border border-hg-border w-full text-body2-medium focus:outline-none focus:border-hgcare-primary"
            />
            <div
              v-if="isDropdownOpen === true"
              id="dropdownScrollBox"
              class="absolute pt-[5px] text-body2-medium scroll-sm flex flex-col w-full top-[43px] border border-hg-border rounded-b-[5px] max-h-[260px] overflow-y-auto bg-hg-box001 z-20"
            >
              <template v-for="(dropdownItem, i) in dropdownValues" :key="i">
                <div
                  v-if="i !== 0"
                  class="mx-[20px] w-full bg-hg-border h-[1px] shrink-0"
                ></div>
                <div
                  @click.prevent="dropdownItemClick(dropdownItem)"
                  class="px-[20px] py-[10px] hover:bg-hg-box002 cursor-pointer"
                  :class="[
                    dropdownItem.text === dropdownSelected
                      ? 'bg-hg-box002 text-hgcare-primary text-body2-bold'
                      : 'bg-hg-box001 text-hg-info',
                    i === dropdownCursor ? 'bg-hg-box002' : '',
                  ]"
                >
                  <p>{{ dropdownItem.name }}</p>
                </div>
              </template>
            </div>
          </div>
          <div class="mt-[30px] flex flex-col items-center">
            <p class="text-body2-medium text-hg-sysRed">현재 담당 센터</p>
            <p class="mt-[10px] text-h2-bold text-hg-info">
              {{ companyInfo.centerName }}
            </p>
          </div>
          <div class="mt-auto flex justify-center pt-[40px]">
            <button
              :disabled="dropdownSelectedIdx === 0"
              @click="editCenter(dropdownSelectedIdx)"
              class="button-primary"
            >
              {{ companyInfo.centerIdx === 0 ? '설정하기' : '변경하기' }}
            </button>
          </div>
        </div>
        <div
          class="bg-hg-box001 col-span-6 xl:col-span-3 shadow-hg px-[22px] pt-[30px] pb-[40px]"
        >
          <div class="flex justify-between items-center mb-[20px]">
            <p class="text-h2-bold">허그쌤</p>
            <!-- <div class="flex items-center divide-x divide-hg-border">
              <p class="px-[16px] text-body2-medium">누적 이용자수</p>
              <p class="px-[16px] text-body2-medium">누적 이용자수</p>
            </div> -->
          </div>
          <Line
            id="my-chart-id"
            :options="chartOptions"
            :data="chartDataSsam"
          />
        </div>
        <div
          class="bg-hg-box001 col-span-6 xl:col-span-3 shadow-hg px-[22px] pt-[30px] pb-[40px]"
        >
          <div class="flex justify-between items-center mb-[20px]">
            <p class="text-h2-bold">허그프렌즈</p>
            <div class="flex items-center divide-x divide-hg-border">
              <!-- <p class="px-[16px] text-body2-medium">누적 이용자수</p>
              <p class="px-[16px] text-body2-medium">누적 이용자수</p> -->
            </div>
          </div>
          <Line
            id="my-chart-id"
            :options="chartOptions"
            :data="chartDataFriends"
          />
        </div>
      </div>
      <!-- Main Contents end -->
      <!-- FOOTER start -->
      <div
        class="mt-auto shrink-0 pl-[7%] pr-[3%] py-[20px] w-full h-[150px] bg-hg-sub"
      >
        <div class="w-full h-full flex">
          <div
            class="flex flex-col justify-between text-body3-medium text-hg-disabled"
          >
            <div class="mb-[12px]">
              <img src="@/assets/logos/footer.svg" alt="허그케어" />
            </div>
            <div class="hidden xl:block">
              <p>
                상호명 : (주)이랩ㅣ사업자번호 : 852-88-01728ㅣ문의 전화 :
                010-9695-3180
              </p>
              <p>
                사업장 주소 : 경기 김포시 김포한강10로133번길 127,
                디원지식산업센터,513호
              </p>
              <p>대표자명 : 이혜선, 황예지ㅣ개인정보 보호 관리자 : 최윤복</p>
            </div>
          </div>
          <div class="ml-auto flex flex-col justify-end">
            <p class="text-body4-medium text-hg-disabled">
              Copyright ⓒ 2024 Education-LAB All rights reserved
            </p>
          </div>
        </div>
      </div>
      <!-- FOOTER end -->
    </div>
    <div v-if="isModalOpen === true">
      <div class="modal-bg"></div>
      <div
        class="modal-box-lg flex flex-col items-center shadow-xl"
        @blur="isModalOpen = false"
      >
        <div
          class="w-full h-[76px] shrink-0 bg-hgcare-primary002 flex items-center justify-between pl-[22px]"
        >
          <p class="text-hg-default2 text-h2-bold">센터 설정</p>
          <div
            class="px-[22px] hover:bg-hgcare-primary h-full flex items-center cursor-pointer"
            @click="isModalOpen = false"
          >
            <img src="@/assets/icons/close.svg" />
          </div>
        </div>
        <div class="w-full shrink-0 pt-[30px] pb-[70px] px-[7%]"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDashboard, getSearchCenter, editCenter } from '@/apis/home';
import store from '@/store';
import { Line } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from 'chart.js';
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
);

ChartJS.defaults.font.size = 12;
ChartJS.defaults.font.family = 'Pretendard';
ChartJS.defaults.font.weight = '400';

export default {
  name: 'HomeView',
  components: {
    Line,
  },
  data() {
    return {
      isMounted: false,
      userInfo: {},
      companyInfo: {},
      isModalOpen: false,
      isDropdownOpen: false,
      Icons: {
        add: require('@/assets/icons/add.svg'),
      },
      summaryData: {
        todayDate: '', // '2024.01.09 (월)',
        teacherData: {
          totalCount: 0,
          videoCount: 0,
          chatCount: 0,
          testCount: 0,
        },
        studentData: {
          totalCount: 0,
          videoCount: 0,
          chatCount: 0,
          testCount: 0,
        },
      },
      dropdownSelected: '',
      dropdownSelectedIdx: 0,
      dropdownCursor: 0,
      dropdownValues: [
        /* { idx: 42, name: '관악고등학교', address: 'ㅇ날미ㅡㄴ' }, */
      ],
      chartOptions: {
        responsive: true,
        interaction: {
          // mode: 'index',
          intersect: false,
        },
        scales: {
          x: {
            grid: {
              color: '#fff',
            },
          },
          y: {
            beginAtZero: true,
            min: 0,
            suggestedMax: 10,
            ticks: {
              //stepSize: 1,
            },
            border: {
              display: false,
            },
          },
        },
      },
      chartDataSsam: {
        labels: [
          '01/01',
          '01/02',
          '01/03',
          '01/04',
          '01/05',
          '01/06',
          '01/07',
          '01/08',
          '01/09',
          '01/10',
          '01/11',
          '01/12',
          '01/13',
          '01/14',
        ],
        datasets: [
          {
            borderWidth: 2, // 라인 넓이
            label: '화상상담', // 데이터 라벨
            backgroundColor: '#FF9C7C',
            pointBackgroundColor: '#FF9C7C',
            fill: true, // 채우기
            barPercentage: 10, // 바 안쪽 넓이
            categoryPercentage: 0.5, // 바 외부 넓이
            tension: 0.1,
            borderColor: '#FF9C7C',
            pointBorderColor: '#FF9C7C',
            pointBorderWidth: 4,
            data: [20, 50, 45, 14, 36, 22, 23, 41, 17, 19, 15, 34, 32, 27],
          },
          {
            borderWidth: 2, // 라인 넓이
            label: '허그봇', // 데이터 라벨
            backgroundColor: '#7CB8FF',
            pointBackgroundColor: '#7CB8FF',
            fill: true, // 채우기
            tension: 0.1,
            borderColor: '#7CB8FF',
            pointBorderColor: '#7CB8FF',
            pointBorderWidth: 4,
            data: [26, 20, 32, 32, 40, 39, 40, 22, 35, 11, 20, 16, 11, 23],
          },
          {
            borderWidth: 2, // 라인 넓이
            label: '자가검진', // 데이터 라벨
            backgroundColor: '#399E55',
            pointBackgroundColor: '#399E55',
            fill: true, // 채우기
            tension: 0.1,
            borderColor: '#399E55',
            pointBorderColor: '#399E55',
            pointBorderWidth: 4,
            data: [18, 16, 11, 23, 26, 20, 32, 32, 40, 39, 40, 22, 35, 11],
          },
        ],
      },
      chartDataFriends: {
        labels: [
          '01/01',
          '01/02',
          '01/03',
          '01/04',
          '01/05',
          '01/06',
          '01/07',
          '01/08',
          '01/09',
          '01/10',
          '01/11',
          '01/12',
          '01/13',
          '01/14',
        ],
        datasets: [
          {
            borderWidth: 2, // 라인 넓이
            label: '화상상담', // 데이터 라벨
            backgroundColor: '#FF9C7C',
            pointBackgroundColor: '#FF9C7C',
            fill: true, // 채우기
            barPercentage: 10, // 바 안쪽 넓이
            categoryPercentage: 0.5, // 바 외부 넓이
            tension: 0.1,
            borderColor: '#FF9C7C',
            pointBorderColor: '#FF9C7C',
            pointBorderWidth: 4,
            data: [20, 50, 45, 14, 36, 22, 23, 41, 17, 19, 15, 34, 32, 27],
          },
          {
            borderWidth: 2, // 라인 넓이
            label: '허그봇', // 데이터 라벨
            backgroundColor: '#7CB8FF',
            pointBackgroundColor: '#7CB8FF',
            fill: true, // 채우기
            tension: 0.1,
            borderColor: '#7CB8FF',
            pointBorderColor: '#7CB8FF',
            pointBorderWidth: 4,
            data: [26, 20, 32, 32, 40, 39, 40, 22, 35, 11, 20, 16, 11, 23],
          },
          {
            borderWidth: 2, // 라인 넓이
            label: '자가검진', // 데이터 라벨
            backgroundColor: '#399E55',
            pointBackgroundColor: '#399E55',
            fill: true, // 채우기
            tension: 0.1,
            borderColor: '#399E55',
            pointBorderColor: '#399E55',
            pointBorderWidth: 4,
            data: [18, 16, 11, 23, 26, 20, 32, 32, 40, 39, 40, 22, 35, 11],
          },
        ],
      },
      // interval for dropdown search
      interval: null,
    };
  },
  async mounted() {
    this.companyInfo = store.state.companyInfo;
    this.userInfo = store.state.userInfo;
    const dashboardRes = await getDashboard();
    console.log(dashboardRes);
    this.summaryData.todayDate = dashboardRes.today;
    this.summaryData.teacherData = dashboardRes.todayTeacherStats;
    this.summaryData.studentData = dashboardRes.todayStudentStats;
    this.chartDataSsam.labels = this.chartDataFriends.labels =
      dashboardRes.dateList;
    this.chartDataSsam.datasets[0].data = dashboardRes.teacherVideoCountList;
    this.chartDataSsam.datasets[1].data = dashboardRes.teacherChatCountList;
    this.chartDataSsam.datasets[2].data = dashboardRes.teacherTestCountList;
    this.chartDataFriends.datasets[0].data = dashboardRes.studentVideoCountList;
    this.chartDataFriends.datasets[1].data = dashboardRes.studentChatCountList;
    this.chartDataFriends.datasets[2].data = dashboardRes.studentTestCountList;

    this.isMounted = true;
  },
  methods: {
    logout() {
      store.commit('userLogout');
      // console.log(store.state.userInfo);
      this.$router.replace('/login');
    },
    dropdownItemClick(dropdownItem) {
      this.dropdownSelected = dropdownItem.name;
      this.dropdownSelectedIdx = dropdownItem.idx;
      this.dropdownCursor = this.dropdownValues.findIndex(
        item => item.name === dropdownItem.name
      );
      this.isDropdownOpen = false;
    },
    dropdownKeyPress(event) {
      if (this.isDropdownOpen === true) {
        var dropdownScrollBox = document.querySelector('#dropdownScrollBox');
        if (event.key === 'ArrowDown') {
          if (this.dropdownCursor < this.dropdownValues.length - 1) {
            this.dropdownCursor++;
            //this.dropdownSelected = this.dropdownValues[this.dropdownCursor].name;
            dropdownScrollBox.scrollTo({
              top: 45 * this.dropdownCursor - 20,
              behavior: 'smooth',
            });
          }
        } else if (event.key === 'ArrowUp') {
          if (this.dropdownCursor > 0) {
            this.dropdownCursor--;
            //this.dropdownSelected = this.dropdownValues[this.dropdownCursor].name;
            dropdownScrollBox.scrollTo({
              top: 45 * this.dropdownCursor - 20,
              behavior: 'smooth',
            });
          }
        } else if (event.key === 'Enter') {
          this.dropdownSelected = this.dropdownValues[this.dropdownCursor].name;
          this.dropdownSelectedIdx =
            this.dropdownValues[this.dropdownCursor].idx;
        }
      }
    },
    getDropdownValues() {
      this.dropdownSelectedIdx = 0;
      var time;
      if (this.interval == null) {
        time = 100;
      } else {
        time = 200;
        clearInterval(this.interval);
      }
      this.interval = setInterval(() => {
        console.log('querying API with ' + this.dropdownSelected);
        if (this.dropdownSelected != '') {
          getSearchCenter(this.dropdownSelected).then(res => {
            console.log(res);
            this.dropdownValues = res.centers;
          });
        }
        clearInterval(this.interval);
        this.interval = null;
      }, time);
    },
    editCenter(centerIdx) {
      editCenter(centerIdx).then(res => {
        console.log(res);
        this.$router.go(0);
      });
    },
  },
};
</script>
