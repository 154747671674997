<template>
  <div
    class="login bg-hg-box002 h-screen flex flex-col justify-center items-center"
  >
    <div class="px-[20px] flex flex-col justify-center items-center">
      <img alt="허그케어" src="../assets/logos/login_center.png" />
      <p class="text-hgcare-primaryPressed text-h3-semibold mb-[50px]">
        For 관리자
      </p>
      <form
        @submit.prevent="login()"
        class="flex flex-col justify-center items-center"
      >
        <input
          type="text"
          placeholder="아이디를 입력해 주세요"
          maxlength="20"
          v-model="id"
          required
          class="mb-[20px] min-w-[350px] h-[60px] px-[16px] py-[18px] text-body2-medium rounded-[10px] bg-hg-box001 placeholder:text-hg-disabled"
        />
        <input
          type="password"
          placeholder="비밀번호를 입력해 주세요"
          minlength="6"
          maxlength="20"
          required
          v-model="password"
          class="mb-[40px] min-w-[350px] h-[60px] px-[16px] py-[18px] text-body2-medium rounded-[10px] bg-hg-box001 placeholder:text-hg-disabled"
        />
        <button
          class="min-w-[350px] h-[55px] rounded-[10px] bg-hgcare-primary focus:bg-hgcare-primaryPressed text-hg-default2 text-body2-bold"
        >
          로그인
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { loginApi } from '@/apis/user';
export default {
  name: 'LoginView',
  data() {
    return {
      id: '',
      password: '',
    };
  },
  methods: {
    async login() {
      const loginRes = await loginApi(this.id, this.password);
      if (loginRes.error === false) {
        // set localstorage accessToken
        localStorage.setItem('ACCESS_TOKEN', loginRes.accessToken);
        // redirect to main page
        this.$router.replace('/');
      } else {
        alert('아이디 또는 비밀번호를 확인해주세요.');
      }
    },
  },
};
</script>
